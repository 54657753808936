
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import {
    ResponseDataChart as responseChart,
    Chart,
    ChartError,
    FilterFormDefinitive,
    SettingsFormInterface
} from "@/interfaces";
import * as types from "@/store/types";
import axios, { AxiosResponse, AxiosError } from "axios";
import { sleep } from "@/utils";
import { TOKEN } from "@/services/auth/auth-service";
import { ResponseError } from "@/interfaces";
@Component({
    components: {
        FilterForm: () =>
            import("@/pages/components/forms/FilterFormHorizontal.vue"),
        CardWidget: () => import("@/components/layout/CardWidget.vue")
    },
    methods: {
        ...mapMutations({
            setProgressBar: types.MUTATE_APP_PROGRESSBAR,
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        }),
        getColor(Nombre) {
            if (Nombre == "Total") return "light-grey";
            else return "white";
        }
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    }
})
export default class Energy extends Vue {
    setProgressBar!: (state: boolean) => void;
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    filterFormPanelShow: boolean | null = false;
    filterForm: FilterFormDefinitive = {
        company: "",
        client: "",
        installation: "",
        elements: [],
        inversors: [],
        dates: [],
        comparative: false,
        days: "10",
        window: "1h",
        valid: true
    };
    userIdentifier?: number;
    client = axios.create();
    toggle_exclusive = 0;
    texto = "";
    traduccion = "";
    urlInstall = "";
    rules = {
        required: value => !!value || "Requerido",
        counter: value => value.length <= 255 || "Maximo 255 caracteres"
    };
    isAdmin = true;
    /**
     * Methods
     */

    mounted() {
        if (Vue.prototype.$user.get().role == "client") {
            this.isAdmin = false;
            this.doSomething();
        }
    }

    async fetchData() {
        this.setProgressBar(true);

        this.setProgressBar(false);
    }

    async translateText<T>(text, lang: string) {
        return this.client
            .request<T>({
                method: "post",
                url:
                    "https://translation.googleapis.com/language/translate/v2?key=AIzaSyBUF0tLWD1fG0PR-9nQ3j7G1ziZxSR615k",
                data: {
                    q: text,
                    target: lang
                }
            })
            .then((res: AxiosResponse) => {
                return res.data;
            })
            .catch((e: AxiosError) => {
                console.error(e);

                const errorData = e.response?.data as ResponseError;
                const txt = errorData ? errorData.detail : e.message;

                throw new Error(txt);
            }) as Promise<T>;
    }

    async translate() {
        let trans: any;
        switch (this.toggle_exclusive) {
            case 1:
                trans = await this.translateText(this.texto, "en");
                break;
            case 2:
                trans = await this.translateText(this.texto, "fr");
                break;
        }
        if (this.toggle_exclusive != 0) {
            this.traduccion = this.convertToPlain(
                trans.data.translations[0].translatedText
            );
        } else {
            this.traduccion = this.texto;
        }
    }

    onFilterUpdated() {
        if (Vue.$cookies.isKey(TOKEN)) {
            //logica de los periodType
            this.doSomething();
        } else {
            this.$router.push("/signin");
        }
    }

    convertToPlain(html) {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    doSomething() {
        if (this.isAdmin) {
            this.urlInstall =
                "https://www.euskabea.com/es/areacliente/instalaciones_embed/?Ncliente=" +
                this.filterForm.client.split(" - ")[0]; //&Npersona=[persona]
        } else {
            this.urlInstall =
                "https://www.euskabea.com/es/areacliente/instalaciones_embed/?Ncliente=" +
                this.userIdentifier;
        }
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
}
